// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// require('jquery')
window.$ = window.jQuery = require('jquery')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('bootstrap')
// require('raty-js')
require('@fortawesome/fontawesome-free/js/all')
import '../scss/application.scss'
import '../js/application'
import '../scss/application'

const images = require.context('../images', true)


// PWA 導入コード
if('serviceWorker' in navigator){
  window.addEventListener('load', function() {
    this.navigator.serviceWorker.register('/service-worker.js', { scope: "/"})
  });
}
